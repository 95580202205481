// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { hanleResponseErrors } from '@helpers/Actions';
import { ls } from '@helpers/LocalStorageEnc';
import baseApi from '@api/index';
import { AWS_LINK } from '@helpers/AwsServices';

export const handleCompanyLogin = createAsyncThunk(
  'company/login',
  async ({ companyId }, { getState, fulfillWithValue, rejectWithValue }) => {
    const response = await baseApi
      .post('/api/company/login', { company_id: companyId })
      .then((res) => res)
      .catch((e) => hanleResponseErrors(e));
    return response.status === 200 ? fulfillWithValue(response.data) : rejectWithValue(response.data);
  }
);

//add company to temporary state
export const companySlice = createSlice({
  name: 'company',
  initialState: {
    company: {}
  },
  reducers: {
    resetCompanyData: (state, action) => {
      state.company = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(handleCompanyLogin.fulfilled, (state, action) => {
        let company = action?.payload?.data?.company || {};

        let companydata = {
          ...company,
          ...{
            logo: `${AWS_LINK}public/test/509-5099390_check-green-check-list-icon-hd-png-download (1).png`,
            registration: {
              number: '123456789'
            },
            billing: {
              address: 'company address from server'
            },
            tax: {
              number: '123-125-1256'
            }
          }
        };
        //state.company = companydata;
        if (action.payload.data) {
          //action.payload.data.company = companydata;
          ls.setItem('company', JSON.stringify(action.payload.data));
        }
      })
      .addCase(handleCompanyLogin.rejected, (state, action) => {
        ls.removeItem('company');
      });
  }
});

export const { resetCompanyData } = companySlice.actions;
export default companySlice.reducer;
