import { ls } from '@src/Services/Helpers/LocalStorageEnc';
import axios from 'axios';

let store;
export const injectStore = (_store) => {
  store = _store;
};

const AUTH_ROUTES = [
  "/api/user/auth/login"
];

export const getBaseUrl = () => {
  /*const subdomain = (store?.getState()?.company?.company?.tenant?.subdomain) ?? undefined;
    return `${process.env.REACT_APP_BASE_API_PROTOCOL}${subdomain ? `${subdomain}.` : ``}${process.env.REACT_APP_BASE_API_DOMAIN}`*/
  //current
  /*const domain = store?.getState()?.company?.company?.tenant?.domain || undefined;
  return `${process.env.REACT_APP_BASE_API_PROTOCOL}${
    domain || process.env.REACT_APP_BASE_API_DOMAIN
  }`;*/
  return `${process.env.REACT_APP_BASE_API_PROTOCOL}${process.env.REACT_APP_BASE_API_DOMAIN}`;
};

const getUserToken = () => ls?.getItem('token') || undefined;
const getRefreshToken = () => ls?.getItem('refresh_token') || undefined;

let baseApi = axios.create({
  //withCredentials: true
});

const onRequest = (config) => {
    const token = getUserToken();
    config.baseURL = getBaseUrl();
    if (token && typeof token === 'string') {
      config.headers.authorization = `Bearer ${token}`;
      //config.headers.acceptLanguage = `en`;
    }
    return config;
};

const onRequestError = (error) => {
  return Promise.reject(error);
};

const onResponse = (response) => {
  return response;
};

const onResponseError = async (err) => {
  const originalConfig = err.config;
  if (!AUTH_ROUTES.includes(originalConfig?.url) && err.response) {
  if (err.response.status === 401 && !originalConfig._retry) {
    originalConfig._retry = true;
    try {
          const rs = await baseApi.post("/api/user/auth/refresh-token", {
            refreshToken: getRefreshToken(),
          });

          const { accessToken } = rs.data;
          //TokenService.updateLocalAccessToken(accessToken);
          return baseApi(originalConfig);

        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    } 
    return Promise.reject(err);
};

baseApi.interceptors.request.use(
  (req) => onRequest(req), 
  (err) => onRequestError(err)
);

baseApi.interceptors.response.use(
  (resp) => onResponse(resp),
  (err) => onResponseError(err)
);

export default baseApi;
