import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import baseApi from '@api/index';
import { links } from '@api/links';

export const getOptions = createAsyncThunk('systemLists/getData', async (params) => {
    let { searchParams, type } = params;
    const uri = links[type];
    if (!uri || !type) {
        throw new Error('Invalid data');
    }

    let query = searchParams && Object.keys(searchParams).length ? `?${searchParams}` : '';
    
    const data = (uri && (uri.length > 0)) ? await baseApi.get(`${uri}${query}`) : [];
    
    return {
        data: data,
        type: type
    };
});

export const systemListsSlice = createSlice({
  name: 'systemLists',
  initialState: {
    data: {},
    selectedValues: {},
    selectedValuesId: [],
  },
  reducers: {
    invalidateList: (state, action) => {
      const type = action.payload;
      state.data = {...state.data, [type]: []};
      return state;
    },
    invalidateAllLists: (state, action) => {
        state.data = [];
        return state;
    },
    setSelectedValues: (state, action) => {
      const { queryPath, name, values } = action?.payload;
      if (values && Array.isArray(values)) {
        state.selectedValues = {
            ...state.selectedValues,
            [queryPath]: values
        }
      }
      return state;
    },
    addSelectedValues: (state, action) => {
      const { queryPath, value } = action?.payload;
      if (!value) {
        return state;
      }
      //get old selected values
      const selectedValues = state.selectedValues ? state.selectedValues[queryPath] : [];
      //check old values and update it
      if(selectedValues && selectedValues.length) {
        state.selectedValues[queryPath] = [...new Set([...selectedValues, value])];
      } else {
        state.selectedValues = {
          ...state.selectedValues,
          [queryPath]: [value]
        };
      }
      //
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOptions.fulfilled, (state, action) => {
        const {type, data} = action.payload;
        state.data = {...state.data, [type]: data}
    }).addCase(getOptions.rejected, (state, action) => {
    
    })
  }
});

export const { invalidateList, invalidateAllLists, addSelectedValues, setSelectedValues } = systemListsSlice.actions;
export default systemListsSlice.reducer;
