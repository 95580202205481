// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { hanleResponseErrors } from '@helpers/Actions';
import baseApi from '@api/index';

const initialState = {
  company: {},
  user: {},
  verification: {
    codes: {
      email: undefined
    },
    declarations: {
      company: undefined
    }
  }
};

export const submitCompany = createAsyncThunk(
  'registerCompany/register',
  async (values, { fulfillWithValue, rejectWithValue }) => {
    //const state = getState();
    //const company = state.registerCompany.company;
    //const user = state.registerCompany.user;
    //post data to db
    const response = await baseApi.post('/api/company/register', values).then((res) => res)
      .catch((e) => hanleResponseErrors(e));
    return response.status === 200
      ? fulfillWithValue(response.data)
      : rejectWithValue(response.data);
  }
);

//add company to temporary in the satate
export const saveCompanyInfo = createAsyncThunk(
  'registerCompany/saveCompanyInfo',
  async (params) => params
);

export const registerCompanySlice = createSlice({
  name: 'registerCompany',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(saveCompanyInfo.fulfilled, (state, action) => {
        state.company = action.payload.company;
        state.user = action.payload.user;
      })
      .addCase(saveCompanyInfo.rejected, (state, action) => {
      
      })
      .addCase(submitCompany.fulfilled, (state, action) => {
      
        /*if (action.payload.data.token) {
        ls.setItem('token', action.payload.data.token)
      }*/
      })
      .addCase(submitCompany.rejected, (state, action) => {
        //error handling if needed
      
      });
  }
});
export const {} = registerCompanySlice.actions;
export default registerCompanySlice.reducer;
