import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { handleConfirmCancel } from '@helpers/ConfirmAlerts';
import baseApi from '@api/index';
import { domNotify } from '@domNotify/index';
import { filterBuilder } from '@helpers/Actions';
import { getOptions } from '@components/ReduxComponents/SingleSelect/store';

export const getData = createAsyncThunk('systemTypes/getData', async (params) => {
  const { type } = params;
  const p = filterBuilder(params);
  if (!type) {
    throw new Error('Error Invalid type');
  }
  const response = await baseApi.get(`/api/system-configs/${type}?${p}`);
  return {
    params,
    data: response.data.data.list.data,
    total: response.data.data.list.total
  };
});

//TODO: handle type refetch for single Select

export const addSystemType = createAsyncThunk(
  'systemTypes/addSystemType',
  async (params, { dispatch, getState }) => {
    let data = [];
    const { type, items } = params;
    items.map((item) => {
      item.status = true;
      data.push(item);
    }); 
    await baseApi.post(`/api/system-configs/${type}`, {
      items: data
    });
    await dispatch(getData(getState().systemTypes.params));
    //refetch options
    await dispatch(getOptions({type: type, searchParams: {}}));
    return items;
  }
);


export const updateSystemType = createAsyncThunk(
  'systemTypes/editSystemType',
  async (params, { dispatch, getState }) => {
    const { type, id, ...restParams } = params;
    await baseApi.put(`/api/system-configs/${type}/${id}`, {
      ...restParams,
    });
    await dispatch(getData(getState().systemTypes.params));
    await dispatch(getOptions({type: type, searchParams: {}}));
    return params;
  }
);

export const updateStatus = createAsyncThunk(
  'systemTypes/editSystemType',
  async (params, { dispatch, getState }) => {
    const { type, id, status } = params;
    await baseApi.put(`/api/system-configs/${type}/${id}`, {
      status: status
    });
    await dispatch(getData(getState().systemTypes.params));
    await dispatch(getOptions({type: type, searchParams: {}}));
    return params;
  }
);

export const deleteSystemType = createAsyncThunk(
  'systemTypes/deleteSystemType',
  async (params, { dispatch, getState }) => {
    const { type, id } = params;
    await baseApi.delete(`/api/system-configs/${type}/${id}`);
    await dispatch(getData(getState().systemTypes.params));
    await dispatch(getOptions({type: type, searchParams: {}}));
    return id;
  }
);

export const handleDelete = createAsyncThunk(
  'systemTypes/handleDelete',
  async (params, { dispatch }) => {
    handleConfirmCancel('Delete', 'This item will be deleted, kindly confim?', false, () => {
      dispatch(deleteSystemType(params));
    });
  }
);

export const submitFile = createAsyncThunk(
  'systemTypes/submitFile',
  async (params, { dispatch, getState }) => {
    const { type, file } = params;
    var bodyFormData = new FormData();
    bodyFormData.append('type', type);
    bodyFormData.append('file', file);
    await baseApi({
      url: `/api/system-configs/${type}/import`,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: bodyFormData
    });
    await dispatch(getData(getState().systemTypes.params));
    return 'success';
  }
);

export const systemTypesSlice = createSlice({
  name: 'systemTypes',
  initialState: {
    isReady: false,
    data: [],
    total: 1,
    params: {},
    allData: [],
    systemType: {}
  },
  reducers: {
    resetSystemType: (state, action) => {
      state = {
        isReady: false,
        data: [],
        total: 1,
        params: {},
        allData: [],
        systemType: {}
      };
      return state;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.params = action.payload.params;
      state.total = action.payload.total;
    }).addCase(getData.rejected, (state, action) => {
      state.data = [];
      state.total = 1;
    }).addCase(deleteSystemType.fulfilled, (state, action) => {
      domNotify({
        type: 'success',
        title: 'Delete Successful',
        text: 'Record Deleted Successfully'
      })
    }).addCase(deleteSystemType.rejected, (state, action) => {
      domNotify({
        type: 'error',
        title: 'Delete Error',
        text: 'Error Occurred While Record Deleting'
      })
    }).addCase(updateSystemType.fulfilled, (state, action) => {
      domNotify({
        type: 'success',
        title: 'Update Success',
        text: 'Record Updated Successfully'
      })
    }).addCase(updateSystemType.rejected, (state, action) => {
      domNotify({
        type: 'error',
        title: 'Update Error',
        text: 'Error Occurred While Updating'
      })
    }).addCase(addSystemType.fulfilled, (state, action) => {
      domNotify({
        type: 'success',
        title: 'Records Submitting',
        text: action?.payload?.message || 'Records Submitted Successfully',
      });
      //invalidateQueries(state?.params?.type);
    }).addCase(addSystemType.rejected, (state, action) => {
      domNotify({
        type: 'error',
        title: 'Error Submitting',
        text: action?.payload?.message || 'Error Occurred While Submitting Records',
      })
    })
  }
});
export const { resetSystemType } = systemTypesSlice.actions;
export default systemTypesSlice.reducer;
