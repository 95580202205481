import { QueryClient } from '@tanstack/react-query';
export const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 1,
        retryDelay: 300,
        keepPreviousData: true,
        staleTime: 1000 * 30, // 30seconds
        cacheTime: 1000 * 30, //30 seconds
        //refetchInterval: 1000 * 5, //30 seconds
        refetchIntervalInBackground: false,
        refetchOnReconnect: true,
        refetchOnWindowFocus: false,
        suspense: false
      },
      mutations: {
        retry: 0,
        retryDelay: 300,
      }
    }
  });