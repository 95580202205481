// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { hanleResponseErrors } from '@helpers/Actions';
import baseApi from '@api/index';

export const handleForgotPassword = createAsyncThunk(
  'forgetUser/forgetPassword',
  async ({ email, company_id }, { fulfillWithValue, rejectWithValue }) => {
    const response = await baseApi
      .post('/api/user/forget_password', { email, company_id })
      .then((res) => res)
      .catch((e) => hanleResponseErrors(e));
    return response.status === 200
      ? fulfillWithValue(response.data)
      : rejectWithValue(response.data);
  }
);

//
export const forgotUserSlice = createSlice({
  name: 'forgetUser',
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(handleForgotPassword.fulfilled, (state, action) => {
      
      })
      .addCase(handleForgotPassword.rejected, (state, action) => {
      
      });
  }
});

export default forgotUserSlice.reducer;
