// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import baseApi from '@api/index';
import { hanleResponseErrors, mapData } from '@helpers/Actions';

export const fetchCountries = createAsyncThunk('helpers/countries', async (_) => {
  const response = await baseApi.get(`/api/common/list/countries`);
  return response.data.data;
});

export const fetchCities = createAsyncThunk('helpers/cities', async ({ id }) => {
  const response = await baseApi.get(`/api/common/list/cities?filter[country_id]=${id}`);
  return response.data.data;
});

export const fetchAreas = createAsyncThunk('helpers/areas', async ({ id }) => {
  const response = await baseApi.get(`/api/common/list/areas?filter[city_id]=${id}`);
  return response.data.data;
});

export const resendMail = createAsyncThunk(
  'helpers/resendMail',
  async ({email}, { fulfillWithValue, rejectWithValue }) => {
    const response = await baseApi
      .post('/api/user/resend-verification-code', {
        email: email
      })
      .then((res) => res)
      .catch((e) => hanleResponseErrors(e));
    return (response.status === 200) ? fulfillWithValue(response.data) : rejectWithValue(response.data);
  }
);

export const resendPhone = createAsyncThunk(
  'helpers/resendPhone',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    const response = await baseApi
      .post('/api/user/resend-verification-code')
      .then((res) => res)
      .catch((e) => hanleResponseErrors(e));
    return response.status === 200
      ? fulfillWithValue(response.data)
      : rejectWithValue(response.data);
  }
);

export const verifyPhone = createAsyncThunk(
  'helpers/verifyPhone',
  async (data, { fulfillWithValue, rejectWithValue }) => {
    const response = await baseApi
      .post('/api/auth/verfiy-phone', { ...data })
      .then((res) => res)
      .catch((e) => hanleResponseErrors(e));
    return response.status === 200
      ? fulfillWithValue(response.data)
      : rejectWithValue(response.data);
  }
);

export const verifyMailAndPhone = createAsyncThunk(
  'helpers/verifyMailAndPhone',
  async (data, { fulfillWithValue, rejectWithValue }) => {
    const response = await baseApi
      .post(
        '/api/auth/verfiy-email-and-phone',
        {
          ...data
        })
      .then((res) => res)
      .catch((e) => hanleResponseErrors(e));
    return response.status === 200
      ? fulfillWithValue(response.data)
      : rejectWithValue(response.data);
  }
);

export const changeEmail = createAsyncThunk(
  'helpers/changeEmail',
  async ({ email }, { fulfillWithValue, rejectWithValue }) => {
    const response = await baseApi
      .post('/api/user/change-email', { email })
      .then((res) => res)
      .catch((e) => hanleResponseErrors(e));
    return response.status === 200
      ? fulfillWithValue(response.data)
      : rejectWithValue(response.data);
  }
);

export const changePhone = createAsyncThunk('helpers/changePhone', async ({ mobile }) => {
  const response = await baseApi
    .post('/api/auth/change-phone', { mobile })
    .then((res) => res)
    .catch((e) => hanleResponseErrors(e));
  return response.status === 200 ? fulfillWithValue(response.data) : rejectWithValue(response.data);
});

export const helpersSlice = createSlice({
  name: 'helpers',
  initialState: {
    countries: [],
    cities: [],
    areas: []
  },
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchCountries.fulfilled, (state, action) => {
        state.countries = mapData(action.payload);
      })
      .addCase(fetchCountries.rejected, (state, action) => {
        state.countries = [];
      })
      .addCase(fetchCities.fulfilled, (state, action) => {
        //console.log("action", action.payload);
        state.cities = mapData(action.payload);
      })
      .addCase(fetchCities.rejected, (state, action) => {
        state.cities = [];
      })
      .addCase(fetchAreas.fulfilled, (state, action) => {
        state.areas = mapData(action.payload);
      })
      .addCase(fetchAreas.rejected, (state, action) => {
        state.areas = [];
      })
      .addCase(resendMail.fulfilled, (state, action) => {
      
      })
      .addCase(resendMail.rejected, (state, action) => {
      
      })
      .addCase(resendPhone.fulfilled, (state, action) => {
      
      })
      .addCase(resendPhone.rejected, (state, action) => {
      
      })
      .addCase(verifyMailAndPhone.fulfilled, (state, action) => {
      
      })
      .addCase(verifyMailAndPhone.rejected, (state, action) => {
      
      })
      .addCase(changeEmail.fulfilled, (state, action) => {
      
      })
      .addCase(changeEmail.rejected, (state, action) => {
      
      })
      .addCase(changePhone.fulfilled, (state, action) => {
      
      })
      .addCase(changePhone.rejected, (state, action) => {
      
      });
  }
});

export default helpersSlice.reducer;
