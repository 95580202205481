//TODO: persist react-query
//https://www.welcomedeveloper.com/managing-cache-react-query

// ** React Imports
import { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
//import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
//
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { compress, decompress } from 'lz-string';

import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
//import { persistStore } from 'redux-persist'
// ** Redux Imports
import { store, persistor } from './redux/store';
// ** Intl, CASL & ThemeColors Context
import ability from './configs/acl/ability';
import { AbilityContext } from './utility/context/Can';
import { ThemeContext } from './utility/context/ThemeColors';
import { PhotoProvider } from 'react-photo-view';

// ** Toaster For All
import Toaster from '@components/Notify';

// ** i18n
import './configs/i18n';

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner';

// ** Ripple Button
import './@core/components/ripple-button';

// ** Fake Database

// ** PrismJS
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/components/prism-jsx.min';

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css';

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss';

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css';
import './@core/scss/core.scss';
import './assets/scss/style.scss';

import 'react-photo-view/dist/react-photo-view.css';
// ** Service Worker
import * as serviceWorker from './serviceWorker';
import { ModalsProvider } from '@providers/ModalProvider';
import { queryClient } from '@providers/QueryClient';
import { Font } from '@react-pdf/renderer';

// import { QueryClient } from '@tanstack/react-query';

//REGISTER ARABIC FONT

Font.register({
  family: 'Vazirmatn',
  src: '/fonts/Vazirmatn.ttf'
});
Font.register({
  family: 'Nunito',
  src: '/fonts/Nunito.ttf'
});
Font.register({
  family: 'Nunito',
  src: '/fonts/NunitoBold.ttf',
  fontWeight: 'bold'
});

/*
const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 1,
        retryDelay: 300,
        keepPreviousData: true,
        staleTime: 1000 * 30, // 30seconds
        cacheTime: 1000 * 30, //30 seconds
        //refetchInterval: 1000 * 5, //30 seconds
        refetchIntervalInBackground: false,
        refetchOnReconnect: true,
        refetchOnWindowFocus: false,
        suspense: false
      },
      mutations: {
        retry: 0,
        retryDelay: 300,
      }
    }
  }); */

//injectStore(store);

const persister = createSyncStoragePersister({
  storage: window.localStorage,
  serialize: (data) => compress(JSON.stringify(data)),
  deserialize: (data) => JSON.parse(decompress(data))
});

//const persistedQueries = ['countries', 'cities', 'areas', 'currencies'];

const persistOptions = {
  persister,
  hydrateOptions: {},
  dehydrateOptions: {
    shouldDehydrateQuery: ({ queryKey, ...options }) => {
      return options?.meta?.offline && true;
    }
  },
  maxAge: Infinity
};

// ** Lazy load app
const App = lazy(() => import('./App'));
ReactDOM.render(
  <Provider store={store}>
    <PersistQueryClientProvider client={queryClient} persistOptions={persistOptions}>
      <PersistGate loading={null} persistor={persistor}>
        <PhotoProvider loop={false}>
          <ModalsProvider>
            <Suspense fallback={<Spinner />}>
              <AbilityContext.Provider value={ability}>
                <ThemeContext>
                  <App />
                  <ToastContainer newestOnTop />
                  <Toaster />
                </ThemeContext>
              </AbilityContext.Provider>
            </Suspense>
          </ModalsProvider>
        </PhotoProvider>
      </PersistGate>
    </PersistQueryClientProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
