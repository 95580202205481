// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios Imports
import axios from 'axios';
import { defaultEvents } from '../Calendar/constants';

export const fetchEvents = createAsyncThunk('companyVacations/fetchEvents', async (data) => {
  const response = await axios.get(`/apps/vacation/events/`, data);
  return {
    value: response.data
  };
});

export const addEvent = createAsyncThunk(
  'companyVacations/addEvent',
  async (data, { dispatch, getState }) => {
    const selectedCalendars = getState().companyVacationsCalendar.selectedCalendars;
    await axios.post(`/apps/vacation/add-event/`, data);
    await dispatch(
      fetchEvents({
        calendars: selectedCalendars
      })
    );
    return {
      value: data.event
    };
  }
);

export const updateEvent = createAsyncThunk(
  'companyVacations/updateEvent',
  async (data, { dispatch, getState }) => {
    const selectedCalendars = getState().companyVacationsCalendar.selectedCalendars;
    await axios.post(`/apps/vacation/update-event/`, data);
    await dispatch(
      fetchEvents({
        calendars: selectedCalendars
      })
    );
    return {
      value: data.event
    };
  }
);

export const updateFilter = createAsyncThunk(
  'companyVacations/updateFilter',
  async (data, { dispatch, getState }) => {
    const selectedCalendars = getState().companyVacationsCalendar.selectedCalendars;
    if (selectedCalendars.includes(data.filter)) {
      await dispatch(
        fetchEvents({
          calendars: selectedCalendars.filter((i) => i !== data.filter)
        })
      );
    } else {
      await dispatch(
        fetchEvents({
          calendars: [...selectedCalendars, data.filter]
        })
      );
    }
    return {
      value: data.filter
    };
  }
);

export const updateAllFilters = createAsyncThunk(
  'companyVacations/updateAllFilters',
  async (data, { dispatch }) => {
    const { value } = data;
    if (value === true) {
      await dispatch(
        fetchEvents({
          calendars: defaultEvents
        })
      );
    } else {
      await dispatch(
        fetchEvents({
          calendars: []
        })
      );
    }
    return {
      value
    };
  }
);

export const removeEvent = createAsyncThunk('companyVacations/removeEvent', async (data) => {
  await axios.delete(`/apps/vacation/remove-event/`, data);
  return {
    value: data.id
  };
});

export const companyVacationsSlice = createSlice({
  name: 'companyVacations',
  initialState: {
    events: [],
    selectedEvent: {},
    selectedCalendars: defaultEvents
  },
  reducers: {
    selectEvent: (state, action) => {
      const { event } = action.payload;
      state.selectedEvent = event;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEvents.fulfilled, (state, action) => {
        const { value } = action.payload;
        state.events = value;
      })
      .addCase(updateFilter.fulfilled, (state, action) => {
        const { value } = action.payload;
        if (state.selectedCalendars.includes(value)) {
          state.selectedCalendars.splice(state.selectedCalendars.indexOf(value), 1);
        } else {
          state.selectedCalendars.push(value);
        }
      })
      .addCase(updateAllFilters.fulfilled, (state, action) => {
        const { value } = action.payload;
        let selected = value === true ? defaultEvents : [];
        state.selectedCalendars = selected;
      });
  }
});

export const { selectEvent } = companyVacationsSlice.actions;

export default companyVacationsSlice.reducer;
