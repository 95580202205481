import AWS from 'aws-sdk';

export const AWS_LINK = `${process.env.REACT_APP_AWS_LINK}`;
export const AWS_KEY = `${process.env.REACT_APP_AWS_ACCESS_KEY}`;
export const AWS_SECRECT_KEY = `${process.env.REACT_APP_AWS_SECRET_ACCESS_KEY}`;
export const AWS_PATH = 'public/test/';
export const NO_LOGO = `${AWS_LINK}${AWS_PATH}no-logo.png`;

const S3_BUCKET = 'igs-cms';

const REGION = 'us-east-1';

AWS.config.update({
  accessKeyId: AWS_KEY,// 'AKIA3OO4YL7ZEFX5EUWM', // process.env.AWS_ACCESS_KEY,
  secretAccessKey: AWS_SECRECT_KEY// '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' // process.env.AWS_SECRET_ACCESS_KEYs,
});

const BUCKET = new AWS.S3({
  params: {
    Bucket: S3_BUCKET
  },
  region: REGION
});

export const uploadFiles = async (e, dropzone = false) => {
  let uploadedFilesArr = [];
  let params = [];
  let files = dropzone ? [e] : e.target.files;
  if (files?.length) {
    Array.from(files).map((file) => {
      params.push({
        ACL: 'public-read',
        Body: file,
        Bucket: S3_BUCKET,
        Key: `${AWS_PATH}${file.name}`
      });
    });
    const responses = await Promise.all(params.map((param) => BUCKET.upload(param).promise()));
    responses.length && responses.map((response) => uploadedFilesArr.push(response.key));
  }
  return uploadedFilesArr;
};
