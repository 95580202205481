// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { hanleResponseErrors } from '@helpers/Actions';
import baseApi from '@api/index';
import { ls } from '@src/Services/Helpers/LocalStorageEnc';

export const restUserPassword = createAsyncThunk(
  'resetUserPassword/submit',
  async (
    { password, password_confirmation, token },
    { fulfillWithValue, rejectWithValue }
  ) => {
    const response = await baseApi.post('/api/user/reset_password', {  password, password_confirmation, token })
      .then((res) => res)
      .catch((e) => hanleResponseErrors(e));
    return response.status === 200 ? fulfillWithValue(response.data) : rejectWithValue(response.data);
  }
);


export const resetUserPasswordSlice = createSlice({
  name: 'resetUserPassword',
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(restUserPassword.fulfilled, (state, action) => {
        localStorage.clear();
        ls.clear();
      })
      .addCase(restUserPassword.rejected, (state, action) => {
        localStorage.removeItem('token');
        ls.clear();
      });
  }
});

export default resetUserPasswordSlice.reducer;
